/**
 * 获取url里的参数
 * @param arg 参数名
 * @returns
 */
 export const getURLString = (arg :string) => {
    var reg = new RegExp("(^|&)" + arg + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null)
        return unescape(r[2]);
    return null;
}